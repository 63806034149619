"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = _ngInjectExport;

var _json = require("../json.parser");

_ngInjectExport.$inject = ["$resource", "$log", "RESOURCES_VARS", "ENV_VARS"];

function _ngInjectExport($resource, $log, RESOURCES_VARS, ENV_VARS) {
  'ngInject';

  return $resource(ENV_VARS.API_URL + '/module_marketplaces/marketplaces/deliveroo/brands/:brandId/restaurants/:restaurantId', {
    restaurantId: '@restaurantId',
    brandId: '@brandId'
  }, {
    getConfiguration: {
      isArray: true,
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: (0, _json.parseJSONData)($log)
    },
    getStoreStatus: {
      url: ENV_VARS.API_URL + '/module_marketplaces/marketplaces/deliveroo/stores/:restaurantId/status',
      method: RESOURCES_VARS.API_METHODS.GET,
      transformResponse: (0, _json.parseJSONData)($log)
    },
    setStoreStatus: {
      url: ENV_VARS.API_URL + '/module_marketplaces/marketplaces/deliveroo/stores/:restaurantId/status',
      method: RESOURCES_VARS.API_METHODS.PATCH,
      transformResponse: (0, _json.parseJSONData)($log)
    }
  });
}