import aboutView from 'app/components/parameters/about/about.view.html';
import channelsView from 'app/components/parameters/channels/channels.view.html';
import alertsView from 'app/components/parameters/alerts/alerts.view.html';
import colorsView from 'app/components/parameters/colors/colors.view.html';
import closeView from 'app/components/parameters/close/close.view.html';
import closeUbereatsView from 'app/components/parameters/close_ubereats/close_ubereats.view.html';
import printView from 'app/components/parameters/print/print.view.html';
import rushView from 'app/components/parameters/rush/rush.view.html';
import recapView from 'app/components/parameters/recap/recap.view.html';
import recapWebView from 'app/components/parameters/recap_web/recap_web.view.html';
import faqView from 'app/components/parameters/faq/faq.view.html';
import closeDeliverooView from 'app/components/parameters/close_deliveroo/close_deliveroo.view.html';

export default function ($stateProvider, $urlRouterProvider, $locationProvider) {
    'ngInject';

    $locationProvider.html5Mode(true);
    $stateProvider
        .state('home.parameters.about', states.about())
        .state('home.parameters.channels', states.channels())
        .state('home.parameters.recap', states.recap())
        .state('home.parameters.recap_web', states.recapWeb())
        .state('home.parameters.alerts', states.alerts())
        .state('home.parameters.colors', states.colors())
        .state('home.parameters.close', states.close())
        .state('home.parameters.close_ubereats', states.closeUbereats())
        .state('home.parameters.print', states.print())
        .state('home.parameters.faq', states.faq())
        .state('home.parameters.rush', states.rush())
        .state('home.parameters.close_deliveroo', states.closeDeliveroo());
}

// eslint-disable-next-line vars-on-top, no-var
var states = {
    about() {
        return {
            url: '/about',
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(aboutView);
                },
            ],
            controller: 'ParametersAboutController',
            controllerAs: 'parametersAboutVm',
        };
    },
    channels() {
        return {
            url: '/channels',
            templateUrl: channelsView,
            controller: 'ParametersChannelsController',
            controllerAs: 'channelsVm',
        };
    },
    alerts() {
        return {
            url: '/alerts',
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(alertsView);
                },
            ],
            controller: 'ParametersAlertsController',
            controllerAs: 'parametersAlertsVm',
        };
    },
    colors() {
        return {
            url: '/colors',
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(colorsView);
                },
            ],
            controller: 'ColorsController',
            controllerAs: 'colorsVm',
        };
    },
    close() {
        return {
            url: '/close',
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(closeView);
                },
            ],
            controller: 'ParametersCloseController',
            controllerAs: 'parametersCloseVm',
        };
    },
    closeUbereats() {
        return {
            url: '/close_ubereats',
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(closeUbereatsView);
                },
            ],
            controller: 'CloseUbereatsController',
            controllerAs: 'closeUbereatsVm',
        };
    },
    print() {
        return {
            url: '/print',
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(printView);
                },
            ],
            controller: 'ParametersPrintController',
            controllerAs: 'parametersPrintVm',
        };
    },
    rush() {
        return {
            url: '/rush',
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(rushView);
                },
            ],
            controller: 'ParametersRushController',
            controllerAs: 'parametersRushVm',
        };
    },
    recap() {
        return {
            url: '/recap',
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(recapView);
                },
            ],
            controller: 'ParametersRecapController',
            controllerAs: 'parametersRecapVm',
        };
    },
    recapWeb() {
        return {
            url: '/recap_web',
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(recapWebView);
                },
            ],
            controller: 'ParametersRecapWebController',
            controllerAs: 'parametersRecapWebVm',
            resolve: {
                deliveryAreas(restaurant, Area) {
                    'ngInject';

                    return Area.getAllByRestaurantId({
                        targetId: restaurant.restaurantId,
                    }).$promise;
                },
            },
        };
    },
    faq() {
        return {
            url: '/faq',
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(faqView);
                },
            ],
            controller: 'ParametersFaqController',
            controllerAs: 'parametersFaqVm',
        };
    },
    closeDeliveroo() {
        return {
            url: '/close_deliveroo',
            templateProvider: [
                '$templateCache',
                function ($templateCache) {
                    return $templateCache.get(closeDeliverooView);
                },
            ],
            controller: 'CloseDeliverooController',
            controllerAs: 'closeDeliverooVm',
        };
    },
};
