export default function ($state, launchdarklyService) {
    'ngInject';

    const vm = this;
    vm.tabs = [];
    vm.currentState = $state.current.name;
    vm.handleTabSwitch = function (state) {
        $state.go(state);
    };
    vm.showDeliverooStoreTab = launchdarklyService.allFlags['ct-810-use-update-delivroo-site-status'];
    vm.$onInit = function () {
        vm.tabs = [
            {
                label: 'PARAMETERS_CLOSE.TAB_LABELS.WEB',
                state: 'home.parameters.close',
            },
            ...(vm.shouldUseUbereats
                ? [
                      {
                          label: 'PARAMETERS_CLOSE.TAB_LABELS.UBEREATS',
                          state: 'home.parameters.close_ubereats',
                      },
                  ]
                : []),
            ...(vm.shouldUseDeliveroo && vm.showDeliverooStoreTab
                ? [
                      {
                          label: 'PARAMETERS_CLOSE.TAB_LABELS.DELIVEROO',
                          state: 'home.parameters.close_deliveroo',
                      },
                  ]
                : []),
        ];
    };
}
