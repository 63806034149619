import restaurantModuleResourceModule from '@innovorder/angularcore/core/resources/restaurant_module/restaurant_module.module';
import angular from 'angular';
import aboutModule from './about/about.module';
import channelsModule from './channels/channels.module';
import recapModule from './recap/recap.module';
import recapWebModule from './recap_web/recap_web.module';
import alertsModule from './alerts/alerts.module';
import colorsModule from './colors/colors.module';
import closeModule from './close/close.module';
import closeUbereatsModule from './close_ubereats/close_ubereats.module';
import closeDeliverooModule from './close_deliveroo/close_deliveroo.module';
import faqModule from './faq/faq.module';
import printModule from './print/print.module';
import rushModule from './rush/rush.module';
import parametersRoutes from './parameters.routes';
import parametersController from './parameters.controller';

const dependencies = [
    aboutModule,
    channelsModule,
    recapModule,
    recapWebModule,
    alertsModule,
    colorsModule,
    closeModule,
    closeUbereatsModule,
    closeDeliverooModule,
    faqModule,
    printModule,
    rushModule,
    restaurantModuleResourceModule,
];

angular
    .module('app.home.parameters', dependencies)
    .config(parametersRoutes)
    .controller('ParametersController', parametersController);
export default angular.module('app.home.parameters').name;
