import angular from 'angular';
import closeDeliverooController from './close_deliveroo.controller';
import closeTabsModule from '../close/close_tabs/close_tabs.module';

const dependencies = [closeTabsModule];

angular
    .module('app.home.parameters.close_deliveroo', dependencies)
    .controller('CloseDeliverooController', closeDeliverooController);
export default angular.module('app.home.parameters.close_deliveroo').name;
