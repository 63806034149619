export default function (
    shouldUseDeliverooAvailability,
    shouldUseUberEatsAvailability,
    Deliveroo,
    restaurant,
    $timeout,
    $q,
    $scope,
    $state,
) {
    'ngInject';

    const vm = this;

    if (!shouldUseDeliverooAvailability) {
        $state.go('home.parameters.close');
    }

    vm.isOpen = false;
    vm.isOpenable = false;
    vm.shouldUseDeliverooAvailability = shouldUseDeliverooAvailability;
    vm.shouldUseUberEatsAvailability = shouldUseUberEatsAvailability;

    vm.save = function () {
        Deliveroo.setStoreStatus({
            restaurantId: restaurant.restaurantId,
            availability: !vm.isOpen,
        }).$promise.then(function (newStoreStatus) {
            vm.isOpen = newStoreStatus.status === 'OPEN';
            vm.isOpenable = !newStoreStatus.isOpen && newStoreStatus.isOpenable;
        });
    };

    function loadState() {
        const deferred = $q.defer();
        const moduleOptions = {
            restaurantId: restaurant.restaurantId,
        };
        Deliveroo.getStoreStatus(moduleOptions)
            .$promise.then(function (storeStatus) {
                vm.isOpen = storeStatus.status === 'OPEN';
                vm.isOpenable = storeStatus.status === 'CLOSED' && storeStatus.isOpenable;

                deferred.resolve();
            })
            .catch(function () {
                deferred.reject();
            });
        return deferred.promise;
    }

    function initState(i) {
        loadState().then(
            function () {
                vm.isLoading = false;
                vm.networkError = false;
            },
            function () {
                vm.isLoading = true;
                vm.networkError = true;
                // eslint-disable-next-line no-restricted-properties
                $timeout(initState, Math.pow(2, (i + 1) % 5) * 1000, true, i + 1);
            },
        );
    }

    vm.isLoading = true;
    vm.networkError = false;
    initState(0);
}
