export default function (
    shouldUseUberEatsAvailability,
    shouldUseDeliverooAvailability,
    Ubereats,
    restaurant,
    $timeout,
    $q,
    $scope,
    $state,
) {
    'ngInject';

    const vm = this;

    if (!shouldUseUberEatsAvailability) {
        $state.go('home.parameters.close');
    }

    vm.isOpen = false;
    vm.suspendedUntil = null;
    vm.action = null;
    vm.isSuspendedIndefinitely = false;
    vm.shouldUseUberEatsAvailability = shouldUseUberEatsAvailability;
    vm.shouldUseDeliverooAvailability = shouldUseDeliverooAvailability;
    $scope.$watch('suspendedUntil', function (suspendedUntil) {
        vm.suspendedUntil = suspendedUntil;
        vm.isSuspendedIndefinitely = isAtLeastOneDayAhead(suspendedUntil);
    });

    const tenYearsInHours = 87600;

    vm.closeDurationOptions = [
        {
            id: 1,
            label: 'PARAMETERS_CLOSE.ONE_HOUR',
            closeDurationHours: 1,
        },
        {
            id: 2,
            label: 'PARAMETERS_CLOSE.TWO_HOURS',
            closeDurationHours: 2,
        },
        {
            id: 3,
            label: 'PARAMETERS_CLOSE.THREE_HOURS',
            closeDurationHours: 3,
        },
        {
            id: 4,
            label: 'PARAMETERS_CLOSE.FIVE_HOURS',
            closeDurationHours: 5,
        },
        {
            id: 5,
            label: 'PARAMETERS_CLOSE.UNTIL_END_OF_DAY',
        },
        {
            id: 6,
            label: 'PARAMETERS_CLOSE.UNTIL_MANUAL',
            closeDurationHours: tenYearsInHours,
        },
    ];

    vm.save = function () {
        const reopenAt = computeReopeningDate();
        if (!reopenAt && vm.isOpen) return;

        Ubereats.setStoreStatus({
            restaurantId: restaurant.restaurantId,
            availability: !vm.isOpen,
            closeUntil: reopenAt?.toISOString(),
        }).$promise.then(function (newStoreStatus) {
            vm.isOpen = newStoreStatus.status === 'ONLINE';
            $scope.suspendedUntil = reopenAt;
        });
    };

    function loadState() {
        const deferred = $q.defer();
        const moduleOptions = {
            restaurantId: restaurant.restaurantId,
        };
        Ubereats.getStoreStatus(moduleOptions)
            .$promise.then(function (storeStatus) {
                vm.isOpen = storeStatus.status === 'ONLINE';
                $scope.suspendedUntil = storeStatus.isOfflineUntil && new Date(storeStatus.isOfflineUntil);
                deferred.resolve();
            })
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            .catch(function (err) {
                deferred.reject();
            });
        return deferred.promise;
    }

    function initState(i) {
        loadState().then(
            function () {
                vm.isLoading = false;
                vm.networkError = false;
            },
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            function (error) {
                vm.isLoading = true;
                vm.networkError = true;
                // eslint-disable-next-line no-restricted-properties
                $timeout(initState, Math.pow(2, (i + 1) % 5) * 1000, true, i + 1);
            },
        );
    }

    function addHoursToDate(hours, date) {
        date.setHours(date.getHours() + hours);
        return date;
    }

    function computeReopeningDate() {
        if (!vm.action) return undefined;
        const now = new Date();
        const selectedActionId = vm.action;
        const selectedActionDuration = vm.closeDurationOptions.find(
            (option) => option.id === selectedActionId,
        ).closeDurationHours;
        if (selectedActionId === 5) {
            return addHoursToDate(4, new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)); // closing till the end of the shift closes stores till 4 am of the next day (Just like doing it on uber's dashboard)
        }
        return addHoursToDate(selectedActionDuration, new Date());
    }

    function isAtLeastOneDayAhead(date) {
        const currentDate = new Date();
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
        const difference = date - currentDate;
        return difference >= oneDayInMilliseconds;
    }

    vm.isLoading = true;
    vm.networkError = false;
    initState(0);
}
