export default function ($state, authService) {
    'ngInject';

    const vm = this;
    vm.showPassword = false;
    vm.submit = function () {
        const deferred = authService.login(vm.email, vm.password, true, null, 'reception');
        deferred.then(function () {
            $state.go('home.orders_home.placed');
        });
        return deferred;
    };
}
